const phoneRegex = /iPhone|iPad|iPod|Android|Windows Phone/;

export default function (context) {
  // Add the userAgent property to the context
  const userAgent = process.server
    ? context.req.headers['user-agent']
    : navigator.userAgent;
  const isMobile = phoneRegex.test(userAgent);
  
  context.store.commit('setUa', userAgent);
  context.store.commit('setMobile', isMobile);
  context.store.commit('setYandex', /YaBrowser/.test(userAgent));
}