import Vue from 'vue';
import sulu from './sulu';

const state = () => ({
  ua: '',
  mobile: false,
  offline: false,
  yandex: false,
  initialOffline: false,
  host: null,
  theme: 'light',
  pushToken: '',
  pushRegistered: false,
});

const mutations = {
  setUa(state, value) {
    state.ua = value;
  },
  setMobile(state, value) {
    state.mobile = value;
  },
  setOffline: (state, value) => {
    state.offline = value;
  },
  setYandex (state, value) {
    state.yandex = value;
  },
  setInitialOffline: (state, value) => {
    state.initialOffline = value;
  },
  setTheme (state, value) {
    state.theme = value;
  },
  setPushToken (state, value) {
    state.pushToken = value;
  },
  setPushRegistered (state, value) {
    state.pushRegistered = value;
  },
};

const getters = {
  isMobile(state) {
    return state.mobile;
  },
  isOffline(state) {
    return state.offline;
  },
  isYandex (state) {
    return state.yandex;
  },
  isInitialOffline(state) {
    return state.initialOffline;
  },
  currentTheme (state) {
    return state.theme;
  },
  pushToken (state) {
    return state.pushToken;
  },
  isPushRegistered (state) {
    return state.pushRegistered;
  },
};

const actions = {
  async nuxtServerInit ({ commit, dispatch }, { app, req, isDev }) {
    const currentLang = app.$cookiz.get('defaultLanguage') || 'ru';
    Vue.prototype.$locale = currentLang;
    await app.i18n.setLocale(currentLang);
    await dispatch('sulu/initSuluSender', { req, isDev });

    const currentTheme = app.$cookiz.get('currentTheme') || 'light';
    commit('setTheme', currentTheme);
  },
  async nuxtClientInit ({ dispatch }, { app, req, isDev }) {
    const currentLang = app.$cookiz.get('defaultLanguage') || 'ru';
    Vue.prototype.$locale = currentLang;
    await app.i18n.setLocale(currentLang);
    await dispatch('sulu/initSuluSender', { req, isDev });
  },
};

export default {
  strict: false,
  state,
  getters,
  mutations,
  actions,
  modules: {
    sulu: {
      namespaced: true,
      ...sulu,
    },
  },
};
