export const START_LOADING_NO_EVENT =  'startLoadingNoEvent';
export const STOP_LOADING_NO_EVENT =  'stopLoadingNoEvent';
export const SET_LOADING_NO_EVENT =  'setLoadingNoEvent';
export const START_LOADING =  'startLoading';
export const STOP_LOADING =  'stopLoading';
export const SET_LOADING =  'setLoading';
export const LOGIN_START =  'loginStart';
export const LOGIN_SUCCESS = 'loginSuccess';
export const LOGIN_ERROR = 'loginError';
export const LOGIN_USER = 'loginUser';
export const LOGIN_CONTRACTS = 'loginContracts';
export const REFRESH_START =  'refreshStart';
export const REFRESH_SUCCESS = 'refreshSuccess';
export const TOKEN_SUCCESS = 'tokenSuccess';
export const TOKEN_CLEAR = 'tokenClear';
export const USER_SUCCESS = 'userSuccess';
export const USER_CLEAR = 'userClear';
export const TOKEN_SUCCESS_NO_EVENT = 'tokenSuccessNoEvent';
export const TOKEN_CLEAR_NO_EVENT = 'tokenClearNoEvent';
export const REFRESH_ERROR = 'refreshError';
export const LOGOUT_START = 'logoutStart';
export const LOGOUT_SUCCESS = 'logoutSuccess';
export const LOGOUT_ERROR = 'logoutError';
export const CONTRACTS_START = 'contractsStart';
export const CONTRACTS_SUCCESS = 'contractsSuccess';
export const CONTRACTS_ERROR = 'contractsError';
export const SET_NATIVE_REFRESH_TOKEN = 'setNativeRefreshToken';
export const CLEAR_NATIVE_REFRESH_TOKEN = 'clearNativeRefreshToken';
