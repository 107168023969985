
export default {
  name: 'update-modal',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    warning: {
      type: String,
      default: '',
    },
    confirmLabel: {
      type: String,
      default: 'Обновить',
    },
    postponeLabel: {
      type: String,
      default: 'Позже',
    },
  },
};
